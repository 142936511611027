import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
import ListText from '@components/organisms/ListText';
import ItemLogo from '@components/molecules/items/ItemLogo/index';
import logo from '@assets/images/projects/fwt/website/FWT-Siteweb_logo.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#F0140A';
const footerRelatedLinks = [
	{
		title: 'ECA Vaud',
		url: '/en/projects/eca-vaud/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectFwtPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/fwt/website/FWT-Siteweb_header.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/fwt/website/FWT-Siteweb_header-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 768, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_001.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_002.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_003.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_004.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_005.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_006.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_007.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_008.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/fwt-freeride-world-tour/',
					lang: 'fr',
				},
			]}
			title="Freeride World Tour- By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="FWT - Freeride World Tour"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Simplify and unify a complex architecture. Propose a
							new digital signature.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'UX Design',
								'UI Design',
								'Headless Web Development',
								'API Integration',
								'Rebranding',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							The Freeride World Tour is the freeride world
							championship that manages the worldwide
							Professional, Qualifier, Challenger and Junior
							Freeride Competitions. The organization called on
							superhuit to redesign its website to better
							distinguish the different competitions and their
							data, while generating transversality to increase
							awareness of the Challenger and Junior Competitions.
							It also wanted a site that can handle traffic peaks
							by being responsive in the different regions of the
							world.
						</p>
						<p>
							In terms of technology, the organization was
							transitioning to a new competition management
							service, which required the integration of an API
							synchronization for data processing.
						</p>

						<h3>Our response</h3>
						<p>
							We supported the organization in clarifying its
							brand architecture and segmenting competitions via
							four distinct sub-sites. The visual identity was
							revisited by creating new graphic codes for digital
							and print, with the aim of simplifying the
							relationship with the FWT brand.
						</p>
						<p>
							When it came to technology, the entire site was
							developed in semi-static mode with React/NextJS to
							reduce server load, supported by a CDN service to
							optimize loading worldwide.
						</p>

						<hr />
						<h3>Find out more</h3>
						<ButtonSecondary
							href="/en/projects/fwt-freeride-world-tour-branding/"
							text="Discover FWT branding project"
							data-target
							className="-red"
						/>
					</div>
				</div>

				<Video
					src="84a160a2afed4f4544c00ac5f7a93c2c"
					alt="Freeride World Tour - Website"
					className="col-full"
				/>

				<div className="box text-content">
					<div className="list-two-columns">
						<div className="text-content">
							<h3>Brand evolution and identity overhaul</h3>
							<p>
								<b>An evolution of visual identity</b>
								<br />
								The first stage of the Freeride World Tour's new
								website is the overhaul of the competition's
								brand identity.
							</p>
							<p>
								This includes the logo, graphic elements and
								typographic choices.
							</p>
							<hr className="element-spacing" />
							<h4
								style={{
									color: accent_color,
									marginBottom: '20px',
								}}
							>
								Discover the Freeride World Tour brand redesign
								project
							</h4>
							<ButtonSecondary
								href="/en/projetcs/fwt-freeride-world-tour-branding/"
								text="Discover"
								data-target
								className="no-spacing"
							/>
						</div>
						<ItemLogo
							image={{
								src: logo,
								alt: 'Logo FWT',
							}}
							imgStyle={{
								width: '512px',
								maxWidth: '100%',
								maxHeight: '100%',
								margin: 'auto',
							}}
						/>
					</div>
				</div>

				<Image
					{...data.image_1.childImageSharp}
					alt="FWT Event"
					className="col-full"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_2.childImageSharp} alt="Ski" />
					<Image
						{...data.image_3.childImageSharp}
						alt="FWT mobile website"
					/>
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Improved rankings and results tracking</h3>
						<p>
							Tracking results is essential on a competition
							website. Since the FWT has a unique way of working,
							which differs from one competition to another on the
							tour, we worked with the FWT to make it as easy as
							possible to understand.
						</p>
						<p>
							<b>
								Greater transparency and the ability to delve
								deeper
							</b>
							<br />
							The points calculation system is more transparent
							and easier to understand, allowing you to delve
							deeper into a result by relating it to its event or
							discovering a video of the rider's run.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_4.childImageSharp}
							alt="Ranking page"
						/>
						<Video
							src="010b9e19e7876574b8bec5227c304323"
							alt="Woman ranking page"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<div className="box text-content">
					<h3>
						Pages that enrich the stories between FWT and its
						audience
					</h3>
					<div className="list-two-columns">
						<div className="text-content grid-spacing">
							<p>
								The site's pages are designed to enrich the
								user’s experience, enabling the audience to
								strengthen its relationship with the world of
								freeriding and riders.
							</p>
							<p>
								The pages dedicated to riders are a perfect
								illustration of this: they offer exclusive
								content, highlighting the most outstanding
								videos, relevant articles and images. This
								includes, of course, a rider's results in the
								various competitions.
							</p>
						</div>
						<div className="text-content">
							<p>
								<b>Reinforced internal networking</b>
								<br />
								All data is interconnected, enabling
								high-quality internal networking. This makes it
								possible to link the density of content
								contained in the FWT over the seasons and riders
								according to years, themes, results and so on.
							</p>
						</div>
					</div>
				</div>

				<Image
					{...data.image_5.childImageSharp}
					alt="Rider Page"
					className="col-full"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<div className="box box--no-padding col-start-1 col-end-1">
						<Video
							src="05417b5af6156ec5e6fb203711e7df8c"
							alt="FWT site web"
							className="video__middle"
						/>
					</div>
					<div className="box box--no-padding col-start-2 col-end-2 col-mobile">
						<Video
							src="57df78ea2715482d7efa05246687b987"
							alt="News FWT"
							className="video__middle"
						/>
					</div>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle="Customer testimonial"
					quote="Our collaboration was seamless and responsive for the duration of the brand and web project. Superhuit managed to take into account our strategic challenges and business constraints to provide us with strong solutions."
					more={
						<>
							<span className="text-content">
								Superhuit was a true strategic partner
								throughout the project, from branding to website
								redesign. Their thoughts and opinions remained
								objective and focused on the objectives we had
								set, but with a constant eye out for where
								improvements could be made.
							</span>
							<span className="text-content">
								Throughout all the meetings, workshops,
								countless emails and phone calls, we were able
								to establish a real relationship of trust with
								the whole team!
							</span>
						</>
					}
					cite="Gabriel Wicki - Head of communication"
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Live and relive the freeride season</h3>
						<p>
							A season is full of emotions, but so is the content
							produced to bring the season to life and allow it to
							be relived. The aim of the media section is to
							showcase all the videos, articles and imagery from
							the competitions.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_6.childImageSharp}
							alt="News and media FWT"
						/>
						<Image
							{...data.image_7.childImageSharp}
							alt="Articles FWT"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Evolution of the Freeride World Tour brand</h3>
						<p>
							Discover the evolution of the Freeride World Tour
							brand and its graphic universe.
						</p>
						<ButtonSecondary
							href="/en/projects/fwt-freeride-world-tour-branding/"
							text="Discover the Freeride World Tour brand redesign project"
							data-target
							className="-red small-spacing"
						/>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_8.childImageSharp}
							alt="Branding FWT"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectFwtPage;
